import { makeAutoObservable } from "mobx"

export class ServerAPImodel{
    constructor(){
        this.baseAPI = localStorage.getItem("baseAPICockpit") ?
            (localStorage.getItem("auth_token_cockpit") ? localStorage.getItem("baseAPICockpit") : this.setInStorage("https://spotlogic-legacy.herokuapp.com/api/v1/")) :
            this.setInStorage("https://spotlogic-legacy.herokuapp.com/api/v1/")
        this.baseAPILogin = this.baseAPI + "sessions/"
        this.baseAPIManagerInfo = this.baseAPI + "users/my_profile"
        this.socketUrl = ""
        this.socketUrl = ""
        makeAutoObservable(this)
    }
    setBaseAPI = serverInd =>{
        if(serverInd === 1){
            this.baseAPI = "https://spotlogic-legacy.herokuapp.com/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://spotlogic-legacy.herokuapp.com/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogic-legacy.herokuapp.com/api/v1/users/my_profile"
        }else if (serverInd === 2){
            this.baseAPI = "https://qa.spotlogic.site/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://qa.spotlogic.site/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://qa.spotlogic.site/api/v1/users/my_profile"
        }else if(serverInd === 3){
            this.baseAPI = "https://xerox.pp.ua/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://xerox.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://xerox.pp.ua/api/v1/users/my_profile"
        }else if(serverInd === 4){
            this.baseAPI = "https://spotlogic-mlogica.pp.ua/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://spotlogic-mlogica.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogic-mlogica.pp.ua/api/v1/users/my_profile"
        }else if(serverInd === 5){
            this.baseAPI = "https://netappspotlogic.pp.ua/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://netappspotlogic.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://netappspotlogic.pp.ua/api/v1/users/my_profile"
        }else if(serverInd === 6){
            this.baseAPI = "https://hashicorp.spotlogic.site/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://hashicorp.spotlogic.site/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://hashicorp.spotlogic.site/api/v1/users/my_profile"
        }else if(serverInd === 7){
            this.baseAPI = "https://solrenewables.spotlogic.site/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://solrenewables.spotlogic.site/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://solrenewables.spotlogic.site/api/v1/users/my_profile"
        }else if(serverInd === 8){
            this.baseAPI = "https://scholarpath.spotlogic.site/api/v1/"
            localStorage.setItem("baseAPICockpit", this.baseAPI)
            this.baseAPILogin = "https://scholarpath.spotlogic.site/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://scholarpath.spotlogic.site/api/v1/users/my_profile"
        }

    }
    setInStorage = api =>{
        localStorage.setItem("baseAPICockpit", api)
        return api
    }
}

export const serverAPImodel = new ServerAPImodel
